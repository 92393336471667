import React from "react";
import FormField from "../../common/components/form/FormField";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import MultilineTextInput from "../../common/components/input/MultilineTextInput";
import FormFieldErrors from "../../common/components/form/FormFieldErrors";

export interface CommentProps {
  length?: number;
}

const CommentsField: React.FC<CommentProps> = ({ length }) => {
  const defaultLength = 50;
  return (
    <FormField name="comments">
      <FormFieldLabel>Comments</FormFieldLabel>
      <FormFieldInput>
        <MultilineTextInput rows={2} maxLength={length || defaultLength} />
      </FormFieldInput>
      <FormFieldErrors />
    </FormField>
  );
};

export default CommentsField;
