import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Title from "../../common/components/view/ViewTitle";
import View from "../../common/components/view/View";
import ViewHeader from "../../common/components/view/ViewHeader";
import {
  useLivestockActivityJobsQuery,
  useUpdateHealthStatusMutation
} from "../graphql";
import FormField from "../../common/components/form/FormField";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import BackButton from "../../common/components/view/BackButton";
import TypeaheadInput from "../../common/components/input/TypeaheadInput";
import Form from "../../common/components/form/Form";
import { useForm, OnSubmit } from "react-hook-form";
import FormSubmit from "../../common/components/form/FormSubmit";
import FormFieldErrors from "../../common/components/form/FormFieldErrors";
import ViewContent from "../../common/components/view/ViewContent";
import InventoryField from "../components/InventoryField";
import Button from "../../common/components/input/Button";
import { useFlash } from "../../common/contexts/flash";

interface FormData {
  job: string;
  healthStatus: string;
}

interface ViewParams {
  activity: string;
}

const ActivityJobView: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams<ViewParams>();
  const formContext = useForm<FormData>();
  const { setMessage } = useFlash();
  const isShipment = params.activity === "shipment";
  const { setValue } = formContext;
  const { data, loading } = useLivestockActivityJobsQuery({
    variables: {
      isShipment: isShipment
    },
    onCompleted({ livestockActivityDefaults: defaults }) {
      if (defaults.job) {
        setValue("job", defaults.job.number);
      }
      if (defaults.job && defaults.job.healthStatus) {
        setValue("healthStatus", defaults.job.healthStatus.code);
      }
    }
  });

  const [post] = useUpdateHealthStatusMutation();

  const onSubmit: OnSubmit<FormData> = data => {
    history.push(`${match.url}/${data.job}`);
  };

  const jobNumber = formContext.watch("job");
  const healthStatus = formContext.watch("healthStatus");
  const job = data
    ? data.livestockActivityJobs.find(job => job.number === jobNumber)
    : undefined;

  useEffect(() => {
    if (job) {
      const healthStatusCode = job.healthStatus ? job.healthStatus.code : null;
      setValue("healthStatus", healthStatusCode);
    }
  }, [job, setValue]);

  const disableChangeHealthButton = () => {
    if (healthStatus === undefined) {
      return true;
    }
    if (job && job.healthStatus && job.healthStatus.code) {
      return healthStatus === job.healthStatus.code;
    }
  };

  const handleHealthChangeSubmit = async () => {
    const completeHealthStatus = data.healthStatus.find(
      status => status.code === healthStatus
    );
    try {
      await post({
        variables: {
          input: {
            group: job.number,
            healthStatus: completeHealthStatus.code
          }
        }
      });
      setMessage({
        message: "Health status updated successfully.",
        level: "success",
        timeout: 2000
      });
    } catch (e) {
      setMessage({
        message: "Health status update failed.",
        level: "error",
        timeout: 2000
      });
    }
    history.go(0);
  };

  return (
    <View>
      <ViewHeader>
        <BackButton />
        <Title>Group Selection</Title>
      </ViewHeader>
      <ViewContent loading={loading}>
        {data && (
          <Form context={formContext} onSubmit={onSubmit}>
            <FormField
              name="job"
              rules={{
                required: "The job field is required."
              }}
            >
              {params.activity === "move" ? (
                <FormFieldLabel>From Group</FormFieldLabel>
              ) : null}
              <FormFieldInput>
                <TypeaheadInput
                  sort={isShipment ? "asc" : "desc"}
                  items={data.livestockActivityJobs.map(job => ({
                    value: job.number,
                    title: `${job.number} ${job.description}`
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>
            {job && (
              <>
                <InventoryField job={job} hideHealthStatus={true} />
                <FormField name="healthStatus">
                  <div className="flex w-full items-center divide-y divide-gray-200 gap-2 justify-between">
                    <div className="flex w-48 items-start">
                      <FormFieldLabel>Health Status</FormFieldLabel>
                    </div>
                    <div>
                      <FormFieldInput>
                        <TypeaheadInput
                          items={data.healthStatus.map(status => ({
                            value: status.code,
                            title: `${status.description}`
                          }))}
                        />
                      </FormFieldInput>
                    </div>
                    <div>
                      <Button
                        onClick={handleHealthChangeSubmit}
                        disabled={disableChangeHealthButton()}
                      >
                        Change Health Status
                      </Button>
                    </div>
                  </div>
                  <FormFieldErrors />
                </FormField>
              </>
            )}
            <FormSubmit>Continue</FormSubmit>
          </Form>
        )}
      </ViewContent>
    </View>
  );
};

export default ActivityJobView;
