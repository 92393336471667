import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import EditJobView from "./EditJobView";
import SelectJobView from "./SelectJobView";

const EditTableRouterView: React.FC = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.url}/:group`} component={EditJobView} />
      <Route path={`${match.url}`} component={SelectJobView} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
};

export default EditTableRouterView;
