import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import MainNav from "./common/components/view/MainNav";
import HomeView from "./common/views/HomeView";
import FuelRouterView from "./fuel/views/FuelRouterView";
import InventoryRouterView from "./inventory/views/InventoryRouterView";
import ActivityView from "./livestock-activity/views/ActivityView";
import MaintenanceRouterView from "./maintenance/views/MaintenanceRouterView";
import ScorecardsRouterView from "./scorecard/views/ScorecardsRouterView";
import { useAuth } from "./user/contexts/auth";
import AccountRouterView from "./user/views/AccountRouterView";
import LoginModal from "./user/views/LoginModal";
import LoginView from "./user/views/LoginView";
import EditTableRouterView from "./table-edit/views/EditTableRouterView";

const UnauthenticatedApp: React.FC = () => {
  return (
    <div className="max-w-3xl h-full mx-auto flex flex-col">
      <Switch>
        <Route exact path="/login" component={LoginView} />
        <Redirect to="/login" />
      </Switch>
    </div>
  );
};

const AuthenticatedApp: React.FC = () => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<any>(location);
  const state = location.state as { modal?: boolean };
  const isModal = state && state.modal && previousLocation !== location;

  useEffect(() => {
    if (!(state && state.modal)) setPreviousLocation(location);
  }, [location, state]);

  return (
    <div className="max-w-3xl h-full mx-auto flex flex-col">
      <Switch location={previousLocation}>
        <Route exact path="/" component={HomeView} />
        <Route path="/livestock-activity" component={ActivityView} />
        <Route path="/scorecard" component={ScorecardsRouterView} />
        <Route path="/fuel" component={FuelRouterView} />
        <Route path="/maintenance" component={MaintenanceRouterView} />
        <Route path="/inventory" component={InventoryRouterView} />
        <Route path="/edit-table" component={EditTableRouterView} />
        <Route path="/account" component={AccountRouterView} />
        <Route exact path="/noauth" component={LoginModal} />
        <Redirect to="/" />
      </Switch>
      {isModal && (
        <Route exact path="/noauth">
          <LoginModal />
        </Route>
      )}
      <MainNav />
    </div>
  );
};

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;
