import React from "react";
import { OnSubmit, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Form from "../../common/components/form/Form";
import FormField from "../../common/components/form/FormField";
import FormFieldErrors from "../../common/components/form/FormFieldErrors";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import FormSubmit from "../../common/components/form/FormSubmit";
import TextInput from "../../common/components/input/TextInput";
import FullPageSlideover from "../../common/components/view/FullPageSlideover";
import { useFlash } from "../../common/contexts/flash";
import { useAuth } from "../contexts/auth";
import { LoginSubmit } from "./LoginView";

interface FormData {
  username: string;
  password: string;
}

export default function LoginModal() {
  const history = useHistory();
  const { login } = useAuth();
  const { addMessage } = useFlash();

  const formContext = useForm<FormData>({
    defaultValues: {
      username: "",
      password: ""
    }
  });

  const closeModal = () => history.goBack();

  const onSubmit: OnSubmit<FormData> = async data =>
    LoginSubmit({ data, login, history, addMessage, back: true });

  return (
    <FullPageSlideover
      open={true}
      toggle={closeModal}
      cancelButtonClass="border border-gray-900 bg-white text-gray-900"
    >
      <Form context={formContext} onSubmit={onSubmit} className="py-4 px-8">
        <FormFieldLabel className="text-xl text-center">
          Please Log Back In
        </FormFieldLabel>
        <div className="text-center">
          You have been logged out due to inactivity. Please log back in and try
          again.
        </div>
        <FormField
          name="username"
          rules={{ required: "The username field is required." }}
        >
          <FormFieldLabel>Username</FormFieldLabel>
          <FormFieldInput>
            <TextInput />
          </FormFieldInput>
          <FormFieldErrors />
        </FormField>
        <FormField
          name="password"
          rules={{ required: "The password field is required." }}
        >
          <FormFieldLabel>Password</FormFieldLabel>
          <FormFieldInput>
            <TextInput type="password" />
          </FormFieldInput>
          <FormFieldErrors />
        </FormField>
        <div className="pt-8">
          <FormSubmit>Log In</FormSubmit>
        </div>
      </Form>
    </FullPageSlideover>
  );
}
