import React from "react";
import { OnSubmit, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Form from "../../common/components/form/Form";
import FormField from "../../common/components/form/FormField";
import FormFieldErrors from "../../common/components/form/FormFieldErrors";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import FormSubmit from "../../common/components/form/FormSubmit";
import TextInput from "../../common/components/input/TextInput";
import View from "../../common/components/view/View";
import ViewContent from "../../common/components/view/ViewContent";
import ViewHeader from "../../common/components/view/ViewHeader";
import Title from "../../common/components/view/ViewTitle";
import { useFlash } from "../../common/contexts/flash";
import {
  InvalidCredentialsError,
  NoAvailableLicenseError,
  useAuth
} from "../contexts/auth";

interface FormData {
  username: string;
  password: string;
}

interface LoginSubmitType {
  data: FormData;
  addMessage: (x: any) => void;
  login: (u: string, p: string) => Promise<void>;
  history: any;
  back?: boolean;
}

export const LoginSubmit: OnSubmit<LoginSubmitType> = async (
  props: LoginSubmitType
) => {
  const { addMessage, data, login, history, back } = props;
  try {
    await login(data.username, data.password);
    back ? history.goBack() : history.push("/");
  } catch (e) {
    if (
      e instanceof InvalidCredentialsError ||
      (e.message && e.message.includes("NAV credentials are invalid"))
    ) {
      addMessage({
        message: "Username or password are incorrect.",
        level: "error"
      });
    } else if (e instanceof NoAvailableLicenseError) {
      addMessage({
        message: "No license is available to access NAV.",
        level: "error"
      });
    } else {
      addMessage({
        message: "An unknown error occurred.",
        level: "error"
      });
    }
  }
};

const LoginView: React.FC = () => {
  const history = useHistory();
  const { login } = useAuth();
  const { addMessage } = useFlash();
  const formContext = useForm<FormData>({
    defaultValues: {
      username: "",
      password: ""
    }
  });

  const onSubmit: OnSubmit<FormData> = async data =>
    LoginSubmit({ data, login, history, addMessage });

  return (
    <View>
      <ViewHeader>
        <Title>Login</Title>
      </ViewHeader>
      <ViewContent>
        <Form context={formContext} onSubmit={onSubmit}>
          <FormField
            name="username"
            rules={{ required: "The username field is required." }}
          >
            <FormFieldLabel>Username</FormFieldLabel>
            <FormFieldInput>
              <TextInput />
            </FormFieldInput>
            <FormFieldErrors />
          </FormField>
          <FormField
            name="password"
            rules={{ required: "The password field is required." }}
          >
            <FormFieldLabel>Password</FormFieldLabel>
            <FormFieldInput>
              <TextInput type="password" />
            </FormFieldInput>
            <FormFieldErrors />
          </FormField>
          <FormSubmit>Log In</FormSubmit>
        </Form>
      </ViewContent>
    </View>
  );
};

export default LoginView;
