import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Title from "../../common/components/view/ViewTitle";
import View from "../../common/components/view/View";
import ViewHeader from "../../common/components/view/ViewHeader";
import BackButton from "../../common/components/view/BackButton";
import Form from "../../common/components/form/Form";
import { useForm, OnSubmit } from "react-hook-form";
import FormSubmit from "../../common/components/form/FormSubmit";
import ViewContent from "../../common/components/view/ViewContent";
import FormField from "../../common/components/form/FormField";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import TypeaheadInput from "../../common/components/input/TypeaheadInput";
import FormFieldErrors from "../../common/components/form/FormFieldErrors";
import HorizontalSpacer from "../../common/components/layout/HorizontalSpacer";
import DateInput from "../../common/components/input/DateInput";
import { useEditJobQuery, useUpdateJobMutation } from "../graphql";
import TextInput from "../../common/components/input/TextInput";
import { useFlash } from "../../common/contexts/flash";

interface FormData {
  status: string;
  startDate: string;
  endDate: string;
  group: string;
  desc: string;
  healthStatus: string;
  pigSourceFlow: string;
  person: string;
  supervisor: string;
  nutrition: string;
  feedmill: string;
}

interface ViewParams {
  group: string;
}

const EditJobView: React.FC = () => {
  const history = useHistory();
  const params = useParams<ViewParams>();
  const formContext = useForm<FormData>();
  const { data, loading } = useEditJobQuery({
    variables: {
      job: params.group
    },
    onCompleted({ jobCard }) {
      const { setValue } = formContext;
      if (jobCard) {
        setValue("group", params.group);
        if (jobCard.status && jobCard.status.length > 0) {
          setValue("status", jobCard.status);
        }
        if (jobCard.startDate) {
          setValue("startDate", jobCard.startDate);
        }
        if (jobCard.endDate) {
          setValue("endDate", jobCard.endDate);
        }
        if (jobCard.description && jobCard.description.length > 0) {
          setValue("desc", jobCard.description);
        }
        if (jobCard.personResponsible) {
          setValue("person", jobCard.personResponsible.number);
        }
        if (jobCard.projectManager) {
          const user = jobCard.projectManager;
          setValue(
            "supervisor",
            user.name || (user.username || "").split("/")[1] || user.username
          );
        }
        if (jobCard.status) {
          setValue("status", jobCard.status);
        }
        if (jobCard.healthStatus) {
          setValue("healthStatus", jobCard.healthStatus);
        }
        if (jobCard.pigSourceFlow) {
          setValue("pigSourceFlow", jobCard.pigSourceFlow);
        }
        if (jobCard.nutrition) {
          setValue("nutrition", jobCard.nutrition);
        }
        if (jobCard.feedmill) {
          setValue("feedmill", jobCard.feedmill);
        }
      }
    }
  });

  const [post] = useUpdateJobMutation();
  const { setMessage } = useFlash();

  const onSubmit: OnSubmit<FormData> = async data => {
    try {
      await post({
        variables: {
          input: {
            ...data
          }
        }
      });
      setMessage({
        message: "Entry recorded successfully.",
        level: "success",
        timeout: 2000
      });
    } catch (e) {
      const error: any = e;
      setMessage({
        message: error.toString(),
        level: "error"
      });
    }
    history.push(`/`);
  };

  const statuses = ["Planning", "Open", "Completed"];

  return (
    <View>
      <ViewHeader>
        <BackButton />
        <Title>{`Edit Group: ${params.group}`}</Title>
      </ViewHeader>
      <ViewContent loading={loading}>
        {data && (
          <Form context={formContext} onSubmit={onSubmit}>
            <FormField name="status">
              <FormFieldLabel>Status</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  value={params.group || ""}
                  items={statuses.map(status => ({
                    value: status,
                    title: status
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="startDate">
              <FormFieldLabel>Start Date</FormFieldLabel>
              <FormFieldInput>
                <DateInput required={true} />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="endDate">
              <FormFieldLabel>End Date</FormFieldLabel>
              <FormFieldInput>
                <DateInput required={true} />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="group">
              <FormFieldLabel>Group No.</FormFieldLabel>
              <FormFieldInput>
                <TextInput />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="desc">
              <FormFieldLabel>Description</FormFieldLabel>
              <FormFieldInput>
                <TextInput />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="healthStatus">
              <FormFieldLabel>Health Status</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  items={data.healthStatus.map(item => ({
                    value: item.code || "",
                    title: item.description || ""
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="pigSourceFlow">
              <FormFieldLabel>Pig Source Flow</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  items={data.pigSourceFlow.map(item => ({
                    value: item.code || "",
                    title: item.description || ""
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="nutrition">
              <FormFieldLabel>Nutrition</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  items={data.nutrition.map(item => ({
                    value: item.code || "",
                    title: item.description || ""
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="feedmill">
              <FormFieldLabel>Feedmill</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  items={data.feedmill.map(item => ({
                    value: item.code || "",
                    title: item.description || ""
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="person">
              <FormFieldLabel>Person Responsible</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="asc"
                  items={data.resources.map(person => ({
                    value: person.number,
                    title: person.name
                  }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>

            <FormField name="supervisor">
              <FormFieldLabel>Supervisor</FormFieldLabel>
              <FormFieldInput>
                <TypeaheadInput
                  sort="desc"
                  items={data.users
                    .filter(user => user.name.length > 0)
                    .map(user => ({
                      value:
                        user.name ||
                        (user.username || "").split("/")[1] ||
                        user.username,
                      title: user.name
                    }))}
                />
              </FormFieldInput>
              <FormFieldErrors />
            </FormField>
            <HorizontalSpacer />
            <FormSubmit />
          </Form>
        )}
      </ViewContent>
    </View>
  );
};

export default EditJobView;
