import React, { useState } from "react";
import FormField from "../../common/components/form/FormField";
import FormFieldInput from "../../common/components/form/FormFieldInput";
import FormFieldLabel from "../../common/components/form/FormFieldLabel";
import StaticValue from "../../common/components/input/StaticValue";
import Divider from "../../common/components/layout/Divider";

export interface InventoryFieldProps {
  className?: string;
  job: PartialJob;
  hideHealthStatus?: boolean;
}

interface PartialJob {
  inventory?: number;
  deadQuantity?: number;
  startQuantity?: number;
  healthStatus?: HealthStatus;
}

export interface HealthStatus {
  code?: string;
  description?: string;
  color?: string;
}

const InventoryField: React.FC<InventoryFieldProps> = ({
  className,
  job,
  hideHealthStatus
}) => {
  const { startQuantity, deadQuantity, inventory, healthStatus } = job;
  const deadsPercent =
    startQuantity > 0 ? (deadQuantity / startQuantity) * 100 : 0;

  let healthConfig = "";
  if (healthStatus && healthStatus.color) {
    healthConfig = `flex items-center content-center justify-items-center text-${healthStatus.color.toLowerCase()}-700`;
  }

  return (
    <div className="py-2">
      <Divider />
      <FormField name="inventory" className={className}>
        <div className="flex w-full items-center divide-y divide-gray-200">
          <div className="flex w-48 items-start">
            <FormFieldLabel>Inventory</FormFieldLabel>
          </div>
          <div>
            <FormFieldInput className="flex" noRegister>
              <StaticValue
                className="flex items-center content-center justify-items-center"
                value={`${inventory}`}
              />
            </FormFieldInput>
          </div>
        </div>
      </FormField>
      <Divider />
      <FormField name="inventory" className={className}>
        <div className="flex w-full items-center divide-y divide-gray-200">
          <div className="flex w-48 items-start text-red-700">
            <FormFieldLabel>Deads</FormFieldLabel>
          </div>
          <div>
            <FormFieldInput className="flex" noRegister>
              <StaticValue
                className="flex items-center content-center justify-items-center text-red-700"
                value={`${deadQuantity} (${deadsPercent.toFixed(1)}%)`}
              />
            </FormFieldInput>
          </div>
        </div>
      </FormField>
      <Divider />
      {!hideHealthStatus && healthStatus && (
        <FormField name="healthStatus" className={className}>
          <div className="flex w-full items-center divide-y divide-gray-200">
            <div className="flex w-48 items-start">
              <FormFieldLabel>Health Status</FormFieldLabel>
            </div>
            <div>
              <FormFieldInput className="flex" noRegister>
                <StaticValue
                  className={healthConfig}
                  value={`${healthStatus.description}`}
                />
              </FormFieldInput>
            </div>
          </div>
        </FormField>
      )}
    </div>
  );
};
export default InventoryField;
